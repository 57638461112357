.AppFooter {
  border-top: 2px solid var(--color-black);
  background-color: var(--color-gray-25);

  &_Top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 25px;
    padding: 64px 0;

    @media (max-width: 1366px) {
      padding: 50px 0;
    }

    @media (max-width: 1024px) {
      padding: 40px 0;
      flex-direction: column;
      gap: 30px;
    }
  }

  &_Bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    padding: 32px 0;

    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      gap: 20px;
    }
  }
}

.AppFooter_Logo {
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 0 auto;
  }

  &_Link {
    span {
      svg {
        width: 82px;

        @media (max-width: 576px) {
          width: 58px;
        }
      }
    }
  }

  span {
    margin-top: 32px;
    font-weight: var(--fw-r);
    font-size: var(--fs-t-md);
    color: var(--color-gray-700);
    max-width: 320px;
  }

  @media (max-width: 1024px) {
    align-items: center;

    span {
      text-align: center;
    }
  }
}

.AppFooter__Count {
  padding: 2px 8px;
  background: var(--color-yellow);
  border-radius: 50%;

  font-family: var(--font-base);
  font-style: normal;
  font-weight: var(--fw-m);
  font-size: var(--fs-t-xs);
  line-height: 1.3;
  text-align: center;
  color: var(--color-black);
}

.AppFooter_Menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  li {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 6px;

    @media (max-width: 576px) {
      justify-content: center;
    }

    a {
      font-family: var(--font-base);
      font-weight: var(--fw-m);
      font-size: var(--fs-t-md);
      color: var(--color-black);
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.6;
      }
    }

    @media (max-width: 1024px) {
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 auto;
  }
}

.AppFooter_Subscribe {
  @media (max-width: 1024px) {
    margin: 0 auto;
    text-align: center;
  }

  label {
    font-family: var(--font-base);
    font-weight: var(--fw-m);
    font-size: var(--fs-t-sm);
    color: var(--color-gray-800);
  }

  &_Inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 17px;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 10px;
    }

    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 17px;
      margin-top: 16px;

      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 10px;
      }

      div {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;

        input {
          display: block;
          padding: 12px 14px 12px 16px;
          height: 45px;
          background: var(--color-white);
          border: 1px solid rgba(var(--color-black-rgb), 0.5);
          box-shadow: 0px 1px 2px rgba(var(--color-gray-800), 0.05);
          border-radius: 30px;

          &::placeholder {
            font-weight: var(--fw-r);
            font-size: var(--fs-t-md);
            color: var(--color-gray-700);
          }

          &:focus {
            background: var(--color-white);
            border: 1px solid rgba(var(--color-black-rgb), 1);
            box-shadow: 0px 1px 2px rgba(var(--color-gray-800), 0.05);
          }
        }

        >span {
          position: absolute;
          bottom: -60%;
          padding-left: 20px;
          font-family: var(--font-secondary);
          font-style: normal;
          font-weight: var(--fw-r);
          font-size: var(--fs-t-md);
          line-height: 1.3;
          //color: var(--color-gray-700);
          color: var(--color-rose);
        }
      }
    }
  }
}

.AppFooter_Copyright {
  font-weight: var(--fw-r);
  font-size: var(--fs-t-md);
  color: var(--color-gray-700);

  @media (max-width: 1024px) {
    order: 2;
  }
}

.AppFooter_BrandKit {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.AppFooter_BrandKit_Btn {

  @media (max-width: 1024px) {
    order: 2;
  }
}

.AppFooter_Socials {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 1024px) {
    order: 1;
  }

  @media (max-width: 320px) {
    flex-direction: column;
  }

  li {
    a {
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}